<template>
      <!-- <header class="bg-white w-full mb-auto shadow">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div class="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div class="flex items-center">
            <a href="#">
              <span class="sr-only">Workflow</span>
              <img class="h-10 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=black" alt="">
            </a>
            <div class="hidden ml-10 space-x-8 lg:block">
              <a href="#" class="text-base font-medium text-black hover:text-indigo-50"> Lorem ipsum Lorem ipsum </a>
            </div>
          </div>
        </div>
      </nav>
    </header> -->

    <router-view :key="$route.fullPath" />
</template>
<script>
  export default {
    watch: {
    },
    methods: {
    },
    created() {
      this.$store.commit('setGlobalLoader',{
          show: true,
          head: "Ładowanie formularza",
          subheader: `Nie wyłączaj podstrony.`,
          success: false
          });
    }
  }
</script>
<style>

body
{
  overflow-x: hidden;
}

</style>

