import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Complaint form',
    meta: { pageTitle: "Clients", title: 'Complaint form' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/NewComplaint.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
