import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // Vue Router import
import store from './store' // Vuex import

import VueShepherd from 'vue-shepherd';
require('shepherd.js/dist/css/shepherd.css');

import './styles/app.css'; // Tailwind css import
import './assets/tailwind.css'

createApp(App).use(store).use(router).use(VueShepherd).mount('#app')

